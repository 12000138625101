var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "공정상세" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        required: true,
                        label: "공정명",
                        name: "processName",
                      },
                      model: {
                        value: _vm.data.processName,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processName", $$v)
                        },
                        expression: "data.processName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "공정코드",
                        name: "processCd",
                      },
                      model: {
                        value: _vm.data.processCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processCd", $$v)
                        },
                        expression: "data.processCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        editable: _vm.editable,
                        label: "공사현장",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.data.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "plantCd", $$v)
                        },
                        expression: "data.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-radio", {
                      attrs: {
                        editable: _vm.editable,
                        comboItems: _vm.psmYnItems,
                        label: "PSM대상",
                        name: "psmFlag",
                      },
                      model: {
                        value: _vm.data.psmFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "psmFlag", $$v)
                        },
                        expression: "data.psmFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-radio", {
                      attrs: {
                        editable: _vm.editable,
                        comboItems: _vm.useFlagItems,
                        label: "사용여부",
                        name: "useFlag",
                      },
                      model: {
                        value: _vm.data.useFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "useFlag", $$v)
                        },
                        expression: "data.useFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "주재료",
                        name: "mainMaterial",
                      },
                      model: {
                        value: _vm.data.mainMaterial,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "mainMaterial", $$v)
                        },
                        expression: "data.mainMaterial",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "생산품",
                        name: "productName",
                      },
                      model: {
                        value: _vm.data.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "productName", $$v)
                        },
                        expression: "data.productName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "근로자수",
                        name: "workerCnt",
                        suffix: "명",
                        type: "number",
                      },
                      model: {
                        value: _vm.data.workerCnt,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "workerCnt", $$v)
                        },
                        expression: "data.workerCnt",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        counter: true,
                        editable: _vm.editable,
                        label: "공정설명",
                        name: "processDesc",
                        rows: 2,
                      },
                      model: {
                        value: _vm.data.processDesc,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processDesc", $$v)
                        },
                        expression: "data.processDesc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfoUp,
                        editable: _vm.editable,
                        label: "공정 사진",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo3,
                        editable: _vm.editable,
                        label: "그 밖의 유해위험정보",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }